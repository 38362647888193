<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/categories" class="back-button"
          ><i class="el-icon-back"></i
        ></router-link>

        <span v-if="$route.name === 'addCategory'">
          Категори нэмэх
          <div class="editField">
            <el-row :gutter="16">
              <el-col :span="16" :offset="4">
                <div class="panel">
                  <el-form
                    :model="category"
                    :rules="rules"
                    ref="category"
                    label-width="220px"
                    class="demo-ruleForm"
                  >
                    <el-form-item label="Категори монгол нэр" prop="name_mon">
                      <el-input
                        style="width:250px"
                        size="mini"
                        v-model="category.name_mon"
                        placeholder="Категори монгол нэр оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Категори англи нэр" prop="name_eng">
                      <el-input
                        style="width:250px"
                        size="mini"
                        v-model="category.name_eng"
                        placeholder="Категори англи нэр оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Өнгө" prop="color">
                      <el-color-picker
                        v-model="category.color"
                      ></el-color-picker>
                    </el-form-item>
                    <el-form-item label="Категори зураг" prop="img_url">
                      <el-input
                        type="url"
                        style="width:250px"
                        size="mini"
                        v-model="category.img_url"
                        placeholder="Категори зурагны url оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Статус" prop="is_active">
                      <el-switch v-model="category.is_active"></el-switch>
                    </el-form-item>
                    <el-form-item label="Эрэмбэ" prop="sort">
                      <el-input-number
                        :precision="2"
                        :step="0.1"
                        :min="1"
                        size="mini"
                        v-model="category.sort"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item label="Тоо" prop="count">
                      <el-input-number
                        :precision="2"
                        :step="0.1"
                        :min="1"
                        size="mini"
                        v-model="category.count"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item label="Төрөл /сервес/" prop="service">
                      <!-- <el-select
                        v-model="category.service"
                        placeholder="Шилжих хуудас сонгоно уу"
                      >
                        <el-option label="Хоол" value="food"></el-option>
                        <el-option
                          label="Ахуйн бараа"
                          value="goods"
                        ></el-option>
                        <el-option label="Хүнс" value="grocery"></el-option>
                        <el-option label="Бэлэг" value="gift"></el-option>
                        <el-option
                          label="Шингэн хүнс"
                          value="drinks"
                        ></el-option>
                      </el-select> -->
                      <el-select
                        placeholder="Үйлчилгээ"
                        v-model="category.service"
                      >
                        <el-option
                          v-for="service in servicesList"
                          :label="service.name_mon"
                          :value="service.name_eng"
                          :key="service.name_eng"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-button
                      type="primary"
                      style="margin-right: 20;"
                      @click="save"
                      >Нэмэх</el-button
                    >
                    <router-link to="/banner" class="back-button">
                      <el-button>
                        Буцах
                      </el-button>
                    </router-link>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
        </span>
      </h3>
    </div>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
export default {
  created() {
    this.getServices();
    if (this.$route.name === "createCategory") {
      this.$notify({
        title: "Категори мэдээлэл нэмэх хуудас",
        message: "Мэдээллээ бүрэн гүйцэт оруулна уу",
        type: "info"
      });
    }
  },

  data() {
    return {
      servicesList: [],
      loadUpload: false,
      imageNull: "none",
      bannerDetail: {},
      category: {
        img_url: "",
        name_mon: "",
        name_eng: "",
        color: "",
        count: 0,
        sort: 0,
        service: "",
        is_active: false,
        deleted: false,
        type: "defM"
      },
      rules: {
        name_mon: [
          {
            required: true,
            message: "Та категори нэрээ оруулна уу",
            trigger: "blur"
          },
          {
            min: 3,
            message: "Хамгийн багадаа 3 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        name_eng: [
          {
            required: true,
            message: "Та категори англи нэр уу",
            trigger: "blur"
          }
        ],
        color: [
          {
            required: true,
            message: "Та өнгө сонгоно уу",
            trigger: "change"
          }
        ],
        end_date: [
          {
            type: "date",
            required: true,
            message: "Дуусгах өдөр сонгоно уу",
            trigger: "change"
          }
        ],
        img_url: [
          {
            required: true,
            message: "Та зурагны uri оруулна уу",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    save() {
      this.$refs.category.validate(valid => {
        if (valid) {
          if (this.$route.name === "addCategory") {
            service.addCategory(this.category).then(response => {
              if (response.type) {
                this.$notify({
                  title: "Амжилттай",
                  message: "Категори нэмэгдлээ",
                  type: "success"
                });
                this.$router.go(-1);
              } else if (!response.type) {
                this.$notify({
                  title: "Амжилтгүй",
                  message:
                    "Категори нэмэхэд алдаа гарлаа" +
                    " " +
                    response.error.message,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getServices() {
      service.getServices(false, "active", true).then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    }
  }
};
</script>
<style>
.editField {
  margin-top: 25px;
}
</style>
